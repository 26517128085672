/* Write your CSS here
.main-home-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
}

.home-container-light {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-grow: 1;
  width: 100%;
}

.home-image {
  width: 100px;
  height: 100px;
}

.home-heading-light {
  color: #000000;
}

.home-container-dark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  flex-grow: 1;
  width: 100%;
}

.home-heading-dark {
  color: white;
} */

:root {
    --font-primary: 'Poppins', sans-serif;
    --font-secondary: 'Arvo', serif;
    --font-code: 'Roboto', monospace;
    --bg-color: #00163f;
    --font-color: #ffffff;
    --btn-bg-color: #ffffff;
    --accent-color: #be7efe;
    --header-shadow: #000 0px 0px 5px;
}

.wallet-btn {
    border-radius: 5rem;
    margin-left: 2rem;
    /* border-color: transparent; */
}

.btn {
    display: inline-block;
    padding: 1rem 2rem;
    background: var(--btn-bg-color);
    color: var(--accent-color);
    font-family: var(--font-secondary);
    font-style: italic;
    font-weight: bold;
    font-size: 1rem;
    text-transform: capitalize;
    transition: all 0.3s ease;
    box-shadow: 4px 4px 0 0 var(--accent-color);
    cursor: pointer;
    position: relative;
    text-decoration: none;
    outline: none;
}

.btn:hover {
    box-shadow: none;
}