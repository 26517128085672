@import "./styles/variables.scss";
.Chat {
    height: 100vh;
    @include flexCenter;
    // .bot {
    //     bottom: 0;
    //     right: 0;
    //     position: fixed;
    //     width: 50%;
    //     // width: 100vw;
    // }
    // .btn {
    //     font-size: 20px;
    //     float: right;
    //     width: 100%;
    //     padding: 8px;
    //     // background-color: rgb(183, 0, 255)
    // }
    .Head {
        height: 130px;
        width: auto;
        margin-bottom: 2rem;
        // width: 100vw;
        // height: 20px;
        // position: absolute;
        // top: 0;
        // left: 1;
        // display: flex;
        // justify-content: center;
        // margin-bottom: 20px;
        @media only screen and (max-width: 768px) {
            // margin-left: 10px;
        }
    }
    .Window {
        max-width: auto;
    }
    .MessageContainer {
        .Message {
            margin-right: 2rem;
            background: $color1;
            border-radius: 10px;
            max-height: 100px;
            overflow: auto;
            display: inline-block;
            color: $color2;
            padding: 0.5rem 1rem;
            margin: 0 2rem 0.5rem 0;
            margin-left: 1rem;
            margin-top: 10px;
            .Typing {
                display: flex;
                justify-content: center;
                svg {
                    height: 10px;
                    width: auto;
                }
            }
        }
    }
    .AnswerContainer {
        margin-top: 2rem;
        .Answer {
            @include ease;
            border: 1px solid $color1;
            border-radius: 10px;
            color: $color2;
            padding: 0.5rem 1rem;
            margin: 0 2rem 0.5rem 0;
            float: right;
            text-decoration: none;
            &:hover {
                background: $color1;
                color: $color2;
                @include ease;
            }
        }
    }
    .Typing {
        background: rgba(0, 0, 0, 0.2);
        display: inline-block;
        border-radius: 10px;
        padding: 0.5rem 1rem;
        svg {
            height: 10px;
            width: auto;
        }
    }
}

.magician__container {
    height: 100vh;
    background-color: #dd823f;
    // background-image: url("images/ego-2bg.png");
    background-image: url("images/longego-2bg-long.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // background for png character
    .Typing {
        margin-left: 16px;
    }
}

.a:hover {
    color: #fff;
}