@import "./styles/variables.scss";
.Chat {
    min-height: 100vh;
    @include flexCenter;
    .Head {
        height: 130px;
        width: auto;
        margin-bottom: 2rem;
        display: block;
    }
    .Window {
        max-width: 400px;
    }
    .MessageContainer {
        .Message {
            margin-right: 2rem;
            background: $color1;
            border-radius: 10px;
            max-height: 100px;
            overflow: auto;
            display: inline-block;
            color: $color2;
            padding: 0.5rem 1rem;
            margin: 0 2rem 0.5rem 0;
            .Typing {
                display: flex;
                justify-content: center;
                svg {
                    height: 10px;
                    width: auto;
                }
            }
        }
    }
    .AnswerContainer {
        margin-top: 2rem;
        .Answer {
            @include ease;
            border: 1px solid $color1;
            border-radius: 10px;
            color: $color1;
            padding: 0.5rem 1rem;
            margin: 0 2rem 0.5rem 0;
            float: right;
            text-decoration: none;
            &:hover {
                background: $color1;
                color: $color2;
                @include ease;
            }
        }
    }
    .Typing {
        background: rgba(0, 0, 0, 0.2);
        display: inline-block;
        border-radius: 10px;
        padding: 0.5rem 1rem;
        svg {
            height: 10px;
            width: auto;
        }
    }
}

.caregiver__container {
    height: 100vh;
    background-color: #319ede;
    // background-image: url("images/order-1bg.png");
    background-image: url("images/caregiver-longBG.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .Typing {
        margin-left: 16px;
    }
}