/* .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.react-icon-styling {
  font-size: 2em;
  color: #fff;
  position: absolute;
  top: 20px;
  /* left: 50%; */
  left: 20px;
  /* width: 60px;
    height: 60px; */
  border-radius: 50%;
  /* background-color: #000; */
}

.containerBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  position: absolute;
  width: 200px;
  text-align: center;
  top: 20px;
  color: #f1f1f1;
  font-size: 0.75em;
}

.subtitle {
  position: absolute;
  width: 200px;
  text-align: center;
  top: 35px;
  color: #f1f1f1;
  font-size: 0.5em;
}

.container-slider {
  max-width: 700px;
  height: 500px;
  margin: 0px auto 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border: 1.5px solid #f1f1f1;
  /* border: 1px solid #41ff46; */
  border-radius: 10px;
  z-index: 9;
}

@media screen and (max-width: 700px) {
  .container-slider {
    margin: 0px 10px;
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.active-anim {
  opacity: 1;
}

.btn-slide {
  /* width: 60px;
    height: 60px; */
  width: 40%;
  height: 40%;
  /* border-radius: 50%; */
  /* background: #f1f1f1; */
  background: transparent;
  border: 0px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}

.prev {
  top: 50%;
  left: 20px;
  transform: translateY(-60%);
}

.next {
  top: 50%;
  right: 20px;
  transform: translateY(-60%);
}

.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 5px;
  background: #f1f1f1;
}

.dot.active {
  background: rgb(32, 32, 32);
}
