/* .body-background { */


/* background-color: red; */


/* width: 400px; */


/* @media screen and (max-width: 768px) {
        
      } */


/* } */

.body-background svg {
    width: 100%;
}

#Head {
    animation: bounce 1.5s linear infinite alternate;
    /* animation: bounce 1.5s infinite ease-in alternate; */
    /* transform-origin: right; */
    /* transform-box: fill-box; */
}

@keyframes bounce {
    0% {
        transform: translateX(18%);
    }
    25% {
        transform: translateX(19%);
    }
    50% {
        transform: translateX(20%);
    }
    75% {
        transform: translateX(19%);
    }
    100% {
        transform: translateX(18%);
    }
}

@media only screen and (max-width: 768px) {
    .body-background {
        width: 300px;
    }
}


/* 
0% {
    transform: translateX(18%);
}
25% {
    transform: translateX(19%);
}
50% {
    transform: translateX(20%);
}
75% {
    transform: translateX(19%);
}
100% {
    transform: translateX(18%);
} */