/* Write your CSS here */

.nav-bar-container-light {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 25px;
    background-color: white;
    border-bottom-style: solid;
    border-bottom-color: #dcdcdc;
    border-bottom-width: 2px;
    height: 80px;
}

.website-logo {
    width: 40px;
    height: 40px;
}

.middle-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    padding: 0px;
}

.list-item {
    font-size: 16px;
    margin-right: 10px;
    font-weight: bold;
}

.link-light {
    color: #171f46;
    text-decoration: none;
}

.theme-button {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-style: none;
    outline: none;
}

.theme-img {
    width: 40px;
    height: 40px;
}

.nav-bar-container-dark {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 25px;
    background-color: #333333;
    height: 80px;
}

.link-dark {
    color: white;
    text-decoration: none;
}